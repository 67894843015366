import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

import css from './FieldNumberInputWithoutNegative.module.css';

const FieldNumberInputWithoutNegativeComponent = props => {
    const {
        rootClassName,
        className,
        inputRootClass,
        customErrorText,
        id,
        label,
        labelClassName,
        input,
        meta,
        inputRef,
        hideErrorMessage,
        errorClassName,
        ...rest
    } = props;

    if (label && !id) {
        throw new Error('id required when a label is given');
    }

    const { valid, invalid, touched, error } = meta;
    const errorText = customErrorText || error;
    const hasError = touched && invalid && errorText;

    const inputClasses = classNames(css.input, {
        [css.inputSuccess]: valid,
        [css.inputError]: hasError,
    });

    // Validator for non-negative numbers only
    const handleChange = e => {
        const value = e.target.value.trim();

        // Use the same regex test from updateValues to validate input
        if (!/^\d*$/.test(value)) {
            console.warn(`Invalid input value: ${value}`);
            return; // Early return to prevent setting the value
        }

        input.onChange(value);
    };

    const inputProps = {
        className: inputRootClass || inputClasses,
        id,
        type: "text",
        inputMode: "numeric",
        ...input,
        ...rest,
        onChange: handleChange,
        ref: inputRef,
    };

    const classes = classNames(rootClassName || css.root, className);
    return (
        <div className={classes}>
            {label ? <label className={labelClassName} htmlFor={id}>{label}</label> : null}
            <input {...inputProps} />
            {!hideErrorMessage && hasError ? <ValidationError className={errorClassName} fieldMeta={{ touched, error: errorText }} /> : null}
        </div>
    );
};

FieldNumberInputWithoutNegativeComponent.defaultProps = {
    rootClassName: null,
    className: null,
    inputRootClass: null,
    customErrorText: null,
    id: null,
    label: null,
    inputRef: null,
    hideErrorMessage: false,
};

FieldNumberInputWithoutNegativeComponent.propTypes = {
    rootClassName: string,
    className: string,
    inputRootClass: string,
    customErrorText: string,
    id: string,
    label: string,
    input: shape({
        onChange: func.isRequired,
        value: string,
    }).isRequired,
    meta: object.isRequired,
};

class FieldNumberInputWithoutNegative extends Component {
    render() {
        return <Field component={FieldNumberInputWithoutNegativeComponent} {...this.props} />;
    }
}

export default FieldNumberInputWithoutNegative;
